<template>
    <div>
        <v-row>
            <v-col cols="12" md="4">
                <v-list>
                    <v-subheader>{{ trans('pages.landingDesign.displaySettings.hideElements') }}</v-subheader>
                    <v-list-item v-for="(name) in item.design.settingsArray.canHideElements" :key="name">
                        <v-list-item-action>
                            <v-checkbox v-model="item.settingsArray.hideElements[name]" @change="changeUrl()"></v-checkbox>
                        </v-list-item-action>
                        <v-list-item-title>{{ trans('pages.landingDesign.displaySettings.items.'+name) }}</v-list-item-title>
                    </v-list-item>
                </v-list>
                <div class="text-center">
                    <v-btn class="hidden-sm-and-down" outlined color="primary" @click="saveHideElements" v-text="trans('buttons.landing.saveAppearance')"></v-btn>
                    <v-btn class="hidden-md-and-up" outlined color="primary" @click="saveHideElements" v-text="trans('buttons.common.save')"></v-btn>
                </div>
                <div class="text-center">
                    <v-dialog
                            fullscreen
                            v-model="offerTypesHideElementsDialog"
                            v-on:click:outside="closeOfferTypesHideElementsDialog"
                    >
                        <landing-offer-types-hide-elements-form
                                :item-data="item"
                                v-on:close="closeOfferTypesHideElementsDialog"
                                v-on:saved="closeOfferTypesHideElementsDialog"
                        ></landing-offer-types-hide-elements-form>
                    </v-dialog>
                    <v-btn
                            class="hidden-sm-and-down mt-3"
                            outlined
                            color="primary"
                            @click="offerTypesHideElementsDialog = true"
                            v-text="trans('buttons.landing.offerTypesHideElements')"
                    ></v-btn>
                </div>
            </v-col>
            <v-col cols="12" md="8">
                <div class="text-center mb-3">
                    <v-btn
                            color="primary"
                            :outlined="mode !== 'mobile'"
                            @click="setIframeStyle('mobile')"
                            class="mr-3 mb-2"
                            :small="$vuetify.breakpoint.xs"
                    >
                        <v-icon left>mdi-cellphone</v-icon>Mobile <template v-if="mode === 'mobile'">({{ scale }}%)</template>
                    </v-btn>
                    <v-btn
                            color="primary"
                            :outlined="mode !== 'desktop'"
                            @click="setIframeStyle('desktop')"
                            :small="$vuetify.breakpoint.xs"
                            class="mb-2"
                    >
                        <v-icon left>mdi-monitor</v-icon>Desktop <template v-if="mode === 'desktop'">({{ scale }}%)</template>
                    </v-btn>
                </div>
                <div>
                    <v-card
                            id="landing-preview-container"
                            :height="containerHeight"
                            :width="mode === 'mobile' ? iframeWidthMobile : 'auto'"
                            :style="mode === 'mobile' ? 'margin-left: max(calc(50% - 180px),0px);' : ''"
                            elevation="5"
                            class="overflow-hidden position-relative"
                    >
                        <iframe
                                id="landing-preview-iframe"
                                class="mobile-preview"
                                v-if="previewUrl"
                                :src="previewUrl"
                                :width="mode === 'mobile' ? iframeWidthMobile : iframeWidthDesktop"
                                :style="iframeStyle"
                        ></iframe>
                    </v-card>
                </div>
            </v-col>
        </v-row>


    </div>
</template>

<script>
    import LandingOfferTypesHideElementsForm from "@/components/user/landing/LandingOfferTypesHideElementsForm";
    export default {
        name: "LandingDisplaySettings",
        components: {LandingOfferTypesHideElementsForm},
        props: {
            item: {
                type: Object
            },
        },
        data(){
            return {
                mode: 'desktop',
                modes: [
                    'mobile', 'desktop'
                ],
                iframeEl: null,
                containerEl: null,
                iframeStyle: {position: 'absolute', top: 0, left: 0, transform: 'scale(1)'},
                containerHeight: 640,
                iframeWidthMobile: 360,
                iframeWidthDesktop: 1200,
                scale: 100,

                offerTypesHideElementsDialog: false,

                previewUrl: this.route('user.landings.preview', this.item.id),
            }
        },
        created(){
            _.each(this.item.design.settingsArray.canHideElements, (fieldName) => {
                if( _.get(this.item, 'settingsArray.hideElements.'+fieldName, null) === null ){
                    _.set(this.item, 'settingsArray.hideElements.'+fieldName, false)
                }
            });

        },
        mounted(){
            this.iframeEl = this.$el.querySelector('#landing-preview-iframe');
            this.containerEl = this.$el.querySelector('#landing-preview-container');
            window.addEventListener('resize', this.debounce(() => {
                this.setIframeStyle();
                },
                200, false), false);
            this.setIframeStyle();
            this.changeUrl();
        },
        methods:{
            closeOfferTypesHideElementsDialog(){
                this.offerTypesHideElementsDialog = false;
                this.changeUrl(true);
            },
            saveHideElements() {
                axios.post(this.route('user.landings.saveHideElements', this.item.id), this.item.settingsArray.hideElements).then( () => {
                    this.$emit('saved')
                    this.$store.dispatch('showSnack', {text: this.trans('pages.app.saveSuccess'), type: 'success'});
                }).catch( err => {
                    console.log(err)
                    this.$store.dispatch('showSnack', {text: this.trans('pages.app.saveError'), type: 'error'});
                })
            },
            changeUrl(force=false){
                let queryString = _.reduce(this.item.settingsArray.hideElements, (query, value, name) => {
                    return query + name+'='+ (value ? '1' : '0') + '&';
                }, '')
                if(force){
                    this.previewUrl = '';
                    setTimeout(() => {
                        this.previewUrl = this.route('user.landings.preview', this.item.id) + '?' + queryString;
                    }, 200);
                } else {
                    this.previewUrl = this.route('user.landings.preview', this.item.id) + '?' + queryString;
                }
            },
            reloadPreview(){
                document.getElementById('landing-preview-iframe').contentWindow.location.reload();
            },
            debounce(func, wait, immediate) {
                let timeout;
                return () => {
                    const context = this, args = arguments;
                    const later = function() {
                        timeout = null;
                        if (!immediate) func.apply(context, args);
                    };
                    const callNow = immediate && !timeout;
                    clearTimeout(timeout);
                    timeout = setTimeout(later, wait);
                    if (callNow) func.apply(context, args);
                };
            },
            setIframeStyle(mode='desktop'){
                this.mode = mode;
                setTimeout(() => {
                    if (this.mode === 'mobile'){
                        let contWidth = this.containerEl.clientWidth;
                        let contHeight = this.containerEl.clientHeight;
                        let proportion = contWidth / this.iframeWidthMobile;
                        if (proportion < 1){
                            this.iframeStyle = {
                                position: 'absolute',
                                top: -( (contHeight/proportion - this.containerHeight)/2 ) + 'px',
                                left: -((this.iframeWidthMobile - this.iframeWidthMobile * proportion)/2)+'px',
                                height: contHeight/proportion + 'px',
                                transform: 'scale('+proportion+')'
                            }
                            this.scale = Math.round(proportion*100);
                        } else {
                            this.iframeStyle = {position: 'absolute', top: 0, height: contHeight + 'px', left: 0, transform: 'scale(1)'}
                            this.scale = 100;
                        }
                        // this.iframeStyle = {position: 'absolute', top: 0, left: 0, height: this.containerHeight+'px', transform: 'scale(1)'}
                        // this.scale = 100;
                    } else {
                        let contWidth = this.containerEl.clientWidth;
                        let contHeight = this.containerEl.clientHeight;
                        let proportion = contWidth / this.iframeWidthDesktop;
                        if (proportion < 1){
                            this.iframeStyle = {
                                position: 'absolute',
                                top: -( (contHeight/proportion - this.containerHeight)/2 ) + 'px',
                                left: -((this.iframeWidthDesktop - this.iframeWidthDesktop * proportion)/2)+'px',
                                height: contHeight/proportion + 'px',
                                transform: 'scale('+proportion+')'
                            }
                            this.scale = Math.round(proportion*100);
                        } else {
                            this.iframeStyle = {position: 'absolute', top: 0, height: contHeight + 'px', left: 0, transform: 'scale(1)'}
                            this.scale = 100;
                        }
                    }
                }, 150);

            }
        },
        computed:{
        }
    }
</script>

<style scoped>
.mobile-preview {
    border: none;
}
</style>