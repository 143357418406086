<template>
    <v-card
        :loading="!(item && options)"
    >
        <v-toolbar
                dark
                color="primary"
        >
            <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn v-if="item.id" dark text @click="save">{{ trans('buttons.common.save') }}</v-btn>
                <v-btn v-else dark text @click="save">{{ trans('buttons.common.create') }}</v-btn>
                <v-btn
                        icon
                        dark
                        @click="$emit('close')"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="item && options">
            <v-container>
                <v-tabs
                        :vertical="!$vuetify.breakpoint.xs"
                        :show-arrows="!$vuetify.breakpoint.xs"
                        v-model="curTab"
                        next-icon="mdi-arrow-right-bold-box-outline"
                        prev-icon="mdi-arrow-left-bold-box-outline"

                >
                    <v-tab>
                        <v-icon left>
                            mdi-cog
                        </v-icon>
                        {{ trans('fields.common.settings') }}
                    </v-tab>
                    <v-tab v-if="item.id" :class="errors.offer_appearances ? 'red--text' : ''">
                        <v-icon left>
                            mdi-animation
                        </v-icon>
                        {{ trans('fields.common.offers') }}
                    </v-tab>
                    <v-tab v-if="item.id && item.design.settingsArray">
                        <v-icon left>
                            mdi-palette-outline
                        </v-icon>
                        {{ trans('pages.landing.appearance') }}
                    </v-tab>

                    <v-tab-item>
                        <v-card flat>
                            <v-card-text>
                                <v-text-field
                                        v-model="item.label"
                                        :label="trans('fields.common.label')"
                                        :error-messages="errors.label"
                                        @input="clearError('label')"
                                ></v-text-field>
                                <v-select
                                        :items="options.languages"
                                        :label="trans('fields.common.language')"
                                        :multiple="false"
                                        v-model="item.language_id"
                                        @change="languageChanged"
                                        :error-messages="errors.language_id"
                                        @input="clearError('language_id')"
                                ></v-select>
                                <v-select
                                        :items="options.countries"
                                        :label="trans('fields.common.country')"
                                        :multiple="false"
                                        v-model="item.country_id"
                                        :error-messages="errors.country_id"
                                        @input="clearError('country_id')"
                                ></v-select>
                                <v-select
                                        v-if="options.landingDesigns"
                                        :items="options.landingDesigns"
                                        :label="trans('fields.common.landingDesign')"
                                        :multiple="false"
                                        v-model="item.design_id"
                                        @change="designChanged"
                                        :error-messages="errors.design_id"
                                        @input="clearError('design_id')"
                                ></v-select>
                                <v-select
                                        v-if="item.design_id && item.language_id && options.designDatas"
                                        :items="options.designDatas"
                                        :label="trans('fields.common.landingDesignData')"
                                        :multiple="false"
                                        v-model="item.design_data_id"
                                        :error-messages="errors.design_data_id"
                                        @input="clearError('design_data_id')"
                                >
                                    <template v-slot:append-outer>
                                        <template v-if="$vuetify.breakpoint.xsOnly">
                                            <v-icon v-if="item.design_data_id && options.designDatasPrivate[item.design_data_id]" @click="landingDesignDataDialogEditShow" color="primary">mdi-pencil</v-icon>
                                            <v-icon @click="landingDesignDataDialogShow" color="primary">mdi-plus</v-icon>
                                        </template>
                                        <template v-else>
                                            <v-icon v-if="item.design_data_id && options.designDatasPrivate[item.design_data_id]" @click="landingDesignDataDialogEditShow" color="primary" class="mr-3">mdi-pencil</v-icon>
                                            <v-btn color="primary" outlined small @click="landingDesignDataDialogShow">{{ trans('buttons.landingDesignData.add') }}</v-btn>
                                        </template>
                                    </template>
                                </v-select>
                                <v-select
                                        v-if="options.domains"
                                        :items="options.domains"
                                        :label="trans('fields.common.domain')"
                                        :multiple="false"
                                        v-model="item.domain_id"
                                        :error-messages="errors.domain_id"
                                        clearable
                                        :no-data-text="trans('domain.noFreeDomains')"
                                        @input="clearError('domain_id')"
                                >
                                    <template v-slot:item="data">
                                        <template v-if="typeof data.item !== 'object'">
                                            <v-list-item-content v-text="data.item"></v-list-item-content>
                                        </template>
                                        <template v-else>
                                            <v-list-item-content>
                                                <v-list-item-title v-html="data.item.text"></v-list-item-title>
                                                <v-list-item-subtitle v-if="data.item.landing" v-html="trans('domain.parkedTo', {landing: data.item.landing})"></v-list-item-subtitle>
                                            </v-list-item-content>
                                        </template>
                                    </template>
                                </v-select>

                                <v-divider class="mt-4 mb-4"></v-divider>
                                <v-row>
                                    <v-subheader>{{ trans('pages.landing.userTracking') }}</v-subheader>
                                </v-row>

                                <v-text-field
                                        v-model="item.yandex_counter_id"
                                        :label="trans('fields.common.yandex_counter_id')"
                                        :error-messages="errors['yandex_counter_id']"
                                        @input="clearError('yandex_counter_id')"
                                ></v-text-field>
                                <v-alert
                                        v-if="item.yandex_counter_id"
                                        border="left"
                                        colored-border
                                        color="success"
                                        elevation="2"
                                        class="mt-3"
                                >
                                    <div v-html="trans('fields.common.yandex_counter_hint')"></div>
                                </v-alert>
                                <v-text-field
                                        v-model="item.google_counter_id"
                                        :label="trans('fields.common.google_counter_id')"
                                        :error-messages="errors['google_counter_id']"
                                        @input="clearError('google_counter_id')"
                                ></v-text-field>
                                <v-alert
                                        v-if="item.google_counter_id"
                                        border="left"
                                        colored-border
                                        color="success"
                                        elevation="2"
                                        class="mt-3"
                                >
                                    <div v-html="trans('fields.common.google_counter_hint')"></div>
                                </v-alert>
                                <v-text-field
                                        v-model="item.facebook_pixel_id"
                                        :label="trans('fields.common.facebook_pixel_id')"
                                        :error-messages="errors['facebook_pixel_id']"
                                        @input="clearError('facebook_pixel_id')"
                                ></v-text-field>
                                <v-text-field
                                        v-model="item.vk_pixel_id"
                                        :label="trans('fields.common.vk_pixel_id')"
                                        :error-messages="errors['vk_pixel_id']"
                                        @input="clearError('vk_pixel_id')"
                                ></v-text-field>
                                <v-alert
                                        border="left"
                                        colored-border
                                        color="warning"
                                        elevation="2"
                                        class="mt-3"
                                >
                                    <h4 v-html="trans('pages.landing.rawScript.warningHeader')"></h4>
                                    <p v-html="trans('pages.landing.rawScript.warning')"></p>
                                    <v-textarea
                                            v-model="item.raw_script"
                                            :label="trans('fields.common.raw_script')"
                                            :error-messages="errors['raw_script']"
                                            @input="clearError('raw_script')"
                                    ></v-textarea>
                                </v-alert>

                                <v-list two-line>
                                    <template v-if="features">
                                        <v-list-item
                                                v-for="feature in features" :key="feature.name"
                                        >
                                            <template v-slot:default="">
                                                <v-list-item-action>
                                                    <v-switch v-model="item.settingsArray.vendor[options.vendorSettings.alias][feature.name]"
                                                    ></v-switch>
                                                </v-list-item-action>
                                                <v-list-item-content>
                                                    <v-list-item-title v-text="trans(feature.label)"></v-list-item-title>
                                                    <p v-html="trans(feature.description)"></p>
                                                    <template v-if="feature.fields && item.settingsArray.vendor[options.vendorSettings.alias][feature.name]">
                                                        <template v-for="(field, index) in feature.fields" >
                                                            <v-text-field
                                                                    v-if="field.type === 'url'"
                                                                    :key="index + '-field'"
                                                                    v-model="item.settingsArray.vendor[options.vendorSettings.alias][feature.name+'_fields'][field.name]"
                                                                    :label="trans(field.label)"
                                                                    :error-messages="errors ? getErrors(`settingsArray.vendor.${options.vendorSettings.alias}.${feature.name}_fields.${feature.name}`) : []"
                                                                    @input="clearError(`settingsArray.vendor.${options.vendorSettings.alias}.${feature.name}_fields.${feature.name}`)"
                                                            ></v-text-field>
                                                            <p :key="index + '-description'" v-if="field.description" v-html="trans(field.description)"></p>
                                                        </template>
                                                    </template>
                                                </v-list-item-content>
                                            </template>
                                        </v-list-item>
                                    </template>

                                    <v-list-item>
                                        <template v-slot:default="">
                                            <v-list-item-action>
                                                <v-switch v-model="item.settingsArray.utmByPassSettings.byPass"
                                                ></v-switch>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title v-text="trans('pages.landing.utmByPass.label')"></v-list-item-title>
                                                <p v-html="trans('pages.landing.utmByPass.description')"></p>
                                                <v-alert
                                                        v-if="item.settingsArray.utmByPassSettings.byPass"
                                                        border="left"
                                                        colored-border
                                                        color="warning"
                                                        elevation="2"
                                                        class="mt-3"
                                                >
                                                    <h4 v-html="trans('pages.landing.utmByPass.warningHeader')"></h4>
                                                    <span v-html="trans('pages.landing.utmByPass.warning')"></span>
                                                </v-alert>
                                            </v-list-item-content>
                                        </template>
                                    </v-list-item>
                                    <v-list-item v-if="item.settingsArray.utmByPassSettings.byPass">
                                        <template v-slot:default="">
                                            <v-list-item-action>
                                                <v-switch v-model="item.settingsArray.utmByPassSettings.custom"
                                                ></v-switch>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title v-text="trans('pages.landing.utmByPassCustom.label')"></v-list-item-title>
                                                <p v-html="trans('pages.landing.utmByPassCustom.description')"></p>
                                            </v-list-item-content>
                                        </template>
                                    </v-list-item>
                                </v-list>
                                <v-list v-if="item.settingsArray.utmByPassSettings.byPass && item.settingsArray.utmByPassSettings.custom">
                                    <v-list-item v-for="(field, index) in item.settingsArray.utmByPassSettings.fields" :key="index">
                                        <template v-slot:default="">
                                            <v-list-item-content>
                                                <v-row>
                                                    <v-col cols="12" sm="6" class="pt-0 pb-0">
                                                        <v-text-field
                                                                dense
                                                                class="ma-0"
                                                                v-model="item.settingsArray.utmByPassSettings.fields[index].incoming"
                                                                :label="trans('fields.landing.fields.incoming')"
                                                                @change="clearError('settingsArray.utmByPassSettings.fields.'+index+'.incoming')"
                                                                :error-messages="errors ? getErrors('settingsArray.utmByPassSettings.fields.'+index+'.incoming') : []"
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" sm="6" class="pt-0 pb-0">
                                                        <v-text-field
                                                                dense
                                                                class="ma-0"
                                                                v-model="item.settingsArray.utmByPassSettings.fields[index].outgoing"
                                                                :label="trans('fields.landing.fields.outgoing')"
                                                                @change="clearError('settingsArray.utmByPassSettings.fields.'+index+'.outgoing')"
                                                                :error-messages="errors ? getErrors('settingsArray.utmByPassSettings.fields.'+index+'.outgoing') : []"
                                                        ></v-text-field>
                                                    </v-col>
                                                </v-row>
                                            </v-list-item-content>
                                            <v-list-item-action>
                                                <v-btn @click="removeCustomField(index)" icon>
                                                    <v-icon>mdi-close</v-icon>
                                                </v-btn>
                                            </v-list-item-action>
                                        </template>
                                    </v-list-item>
                                    <v-row>
                                        <v-col>
                                            <v-btn outlined color="primary" @click="addCustomField">{{ trans('buttons.landing.addParam') }}</v-btn>
                                        </v-col>
                                    </v-row>
                                </v-list>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item v-if="item.id">
                        <v-card flat>
                            <v-card-text>
                                <v-list v-if="item.offer_appearances.length">
                                    <draggable
                                            v-if="item && item.offer_appearances.length"
                                            :list="item.offer_appearances"
                                            ghost-class="ghost"
                                            handle=".draggable-handle"
                                    >
                                        <transition-group name="flip-list">
                                            <v-card
                                                    v-for="(offerAppearance, index) in item.offer_appearances"
                                                    :key="offerAppearance.id"
                                                    class="mb-3"
                                                    elevation="1"
                                                    style="min-height: 32px"
                                            >
                                                <v-list-item
                                                        dense
                                                        style="min-height: 32px"
                                                >
                                                    <v-list-item-icon class="my-1">
                                                        <v-icon class="draggable-handle draggable">mdi-drag</v-icon>
                                                    </v-list-item-icon>
                                                    <v-list-item-content>
                                                        <v-list-item-title>
                                                            {{ options.offersKeyed[offerAppearance.offer_id] }}
                                                            <template v-if="$store.getters.vendorId">
                                                                <offer-appearance-vendor-lock-form
                                                                        :user_id="item.user_id"
                                                                        :parentOptions="options"
                                                                        :parentItem="offerAppearance"
                                                                        :parent-errors="errors.offer_appearances ? errors.offer_appearances[index] : {}"
                                                                />
                                                            </template>
                                                            <template v-else>
                                                                <offer-appearance-form
                                                                        :user_id="item.user_id"
                                                                        :parentOptions="options"
                                                                        :parentItem="offerAppearance"
                                                                        :parent-errors="errors.offer_appearances ? errors.offer_appearances[index] : {}"
                                                                />
                                                            </template>

                                                        </v-list-item-title>

                                                    </v-list-item-content>
                                                    <v-list-item-icon class="my-1">
                                                        <v-icon
                                                                small
                                                                @click="deleteDialogShow(offerAppearance.id, options.offersKeyed[offerAppearance.offer_id])"
                                                        >mdi-delete</v-icon>
                                                    </v-list-item-icon>
                                                </v-list-item>
                                            </v-card>
                                        </transition-group>
                                    </draggable>
                                </v-list>
                                <p class="text-center" v-else>{{ trans('pages.landing.noOffers') }}</p>
                                <v-row>
                                    <v-col
                                            class="text-center"
                                    >
                                        <v-btn
                                                v-if="item.id"
                                                @click="showOfferSelectForm"
                                                color="primary"
                                                outlined
                                        >{{ trans('buttons.common.addOffers') }}</v-btn>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item v-if="item.id && item.design.settingsArray">
                        <v-card flat v-if="curTab === 2">
                            <v-card-text>
                                <landing-display-settings :item="item" v-on:saved="load"></landing-display-settings>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                </v-tabs>
                <v-dialog fullscreen v-model="offerSelectForm">
                    <offer-select-form
                            v-if="offerSelectFormLoaded"
                            :landing_id="item.id"
                            :user_id="item.user_id"
                            :language_id="item.language_id"
                            :country_id="item.country_id"
                            :landing_design_id="item.design_id"
                            v-on:saved="closeOfferSelectForm"
                            v-on:close="closeOfferSelectForm"
                    ></offer-select-form>
                </v-dialog>
                <v-dialog fullscreen v-model="landingDesignDataDialog" v-on:click:outside="closeLandingDesignDataDialog">
                    <landing-design-data-form
                            class="pb-10"
                            v-if="landingDesignDataDialogLoaded"
                            :item-id="landingDesignDataEditId"
                            :landing_design_id="item.design_id"
                            :language_id="item.language_id"
                            v-on:saved="closeLandingDesignDataDialog"
                            v-on:updated="closeLandingDesignDataDialog"
                            v-on:close="closeLandingDesignDataDialog"
                    ></landing-design-data-form>
                </v-dialog>

                <delete-dialog
                        :dialogTrigger="deleteDialog"
                        :item-id="deleteItemId"
                        :delete-route="deleteRoute"
                        @closed="deleteDialogClosed"
                        @deleted="removeOffer"
                >
                    <template v-slot:title>
                        {{ trans('pages.landing.deleteDialogTitle') }}
                    </template>
                    <template v-slot:text>
                        {{ trans('pages.landing.deleteDialogText', {id: deleteItemText ? '"'+deleteItemText+'"' : deleteItemId }) }}
                    </template>
                </delete-dialog>

                <v-card-actions v-if="item && options">
                    <v-spacer></v-spacer>
                    <v-btn text @click="$emit('close')">{{ trans('buttons.common.close') }}</v-btn>
                    <v-btn v-if="item.id" color="blue darken-1" outlined @click="save">{{ trans('buttons.common.save') }}</v-btn>
                    <v-btn v-else color="blue darken-1" outlined @click="save">{{ trans('buttons.common.create') }}</v-btn>
                </v-card-actions>
            </v-container>
        </v-card-text>

    </v-card>
</template>

<script>
    import formValidate from "@/mixins/formValidate";
    import draggable from 'vuedraggable'
    import DeleteDialog from "@/components/commonComponents/DeleteDialog";
    import deleteDialog from "@/mixins/deleteDialog";
    import LandingDisplaySettings from "@/components/user/landing/LandingDisplaySettings";

    export default {
        name: "LandingForm",
        mixins: [
            formValidate,
            deleteDialog,
        ],
        components: {
            LandingDisplaySettings,
            OfferSelectForm: () => import('@/components/user/offer/OfferSelectForm'),
            LandingDesignDataForm: () => import('@/components/user/landingDesignData/LandingDesignDataForm'),
            OfferAppearanceForm: () => import('@/components/user/offerAppearance/OfferAppearanceForm'),
            OfferAppearanceVendorLockForm: () => import('@/components/user/offerAppearance/OfferAppearanceVendorLockForm'),
            draggable,
            DeleteDialog,
        },
        props: {
            itemId: {
                type: [Number, String],
            },
        },
        data(){
            return {
                showRoute: 'user.landings.show',
                dummyRoute: 'user.landings.dummy',
                item: {
                    id: null,
                    label: '',
                    language_id: null,
                    country_id: null,
                    user_id: null,
                    design_id: null,
                    design_data_id: null,
                    offer_appearances: [],
                    domain: null,
                    domain_id: null,
                    yandex_counter_id: null,
                    google_counter_id: null,
                    facebook_pixel_id: null,
                    vk_pixel_id: null,
                    settingsArray:{
                        hideElements: {},
                        utmByPassSettings: {
                            byPass: false,
                            custom: false,
                            fields: [],
                        },
                    },
                },
                error: null,
                options: null,

                deleteRoute: 'user.offerAppearances.delete',

                offerSelectForm: false,
                offerSelectFormLoaded: false,

                landingDesignDataEditId: null,
                landingDesignDataDialog: false,
                landingDesignDataDialogLoaded: false,

                curTab: 0,
            }
        },
        created() {
            this.load();
        },
        methods: {
            load(){
                let id = this.itemId || this.item.id;
                if (id) {
                    axios.get(this.route(this.showRoute, id)).then(response => {
                        this.item = response.data;
                        if (this.item.domain){
                            this.item.domain_id = this.item.domain.id;
                        }
                        this.loadOptions();
                    }).catch(err => {
                        this.error = err.response.data;
                    });
                } else {
                    axios.get(this.route(this.dummyRoute)).then(response => {
                        this.item = response.data;
                        this.loadOptions();
                    }).catch(err => {
                        this.error = err.response.data;
                    });
                }
            },
            initialize(){
                this.load();
            },
            removeOffer(id){
                _.forEach(this.item.offer_appearances, (value, index) => {
                    if (value && value.id === id){
                        this.item.offer_appearances.splice(index, 1);
                        this.deleteDialog = false;
                        this.deleteItemId = null;
                        this.deleteItemText = '';
                        return;
                    }
                })
            },

            addCustomField(){
                this.item.settingsArray.utmByPassSettings.fields.push({
                    incoming: '',
                    outgoing: '',
                });
            },
            removeCustomField(index){
                if (index > -1) {
                    this.item.settingsArray.utmByPassSettings.fields.splice(index, 1);
                }
            },

            landingDesignDataDialogShow(){
                this.landingDesignDataDialog = true;
                this.landingDesignDataDialogLoaded = true;
            },
            landingDesignDataDialogEditShow(){
                this.landingDesignDataEditId = this.item.design_data_id;
                this.landingDesignDataDialog = true;
                this.landingDesignDataDialogLoaded = true;
            },
            closeLandingDesignDataDialog(item) {
                this.landingDesignDataEditId = null;
                this.landingDesignDataDialog = false;
                this.loadOptions();
                if (item){
                    this.item.design_data_id = item.id;
                }
                setTimeout(() => {
                    this.landingDesignDataDialogLoaded = false;
                }, 300);
            },

            showOfferSelectForm(){
                this.offerSelectForm = true;
                this.offerSelectFormLoaded = true;
            },
            closeOfferSelectForm() {
                this.load();
                this.offerSelectForm = false;
                setTimeout(() => {
                    this.offerSelectFormLoaded = false;
                }, 300);
            },

            loadOptions(){
                axios.post(this.route('user.options'), {
                    languages: true,
                    countries: true,
                    landingDesigns: true,
                    cpaNetworks: true,
                    designDatas: this.item ? {design_id: this.item.design_id, language_id: this.item.language_id} : null,
                    offers: true,
                    domains: true,
                    vendorSettings: {landing: true},
                    landingSettings: true,
                }).then( response => {
                    this.options = response.data;
                })
            },
            designChanged(){
                this.item.design_data_id = null;
                this.loadOptions();
            },
            languageChanged(){
                this.item.design_data_id = null;
                this.loadOptions();
            },
            save() {
                if (!this.item.id) {
                    axios.post(this.route('user.landings.store'), this.item).then((response) => {
                        console.log('save success');
                        this.item = response.data;
                        this.$store.dispatch('reachGoal', 'landingCreated');
                        this.$emit('saved');
                        this.$store.dispatch('showSnack', {text: this.trans('pages.app.saveSuccess'), type: 'success'});
                        this.curTab = 1;
                    }).catch( (err) => {
                        this.errors = err.response.data.errors;
                        console.log(err.response.data);
                        this.$store.dispatch('showSnack', {text: this.trans('pages.app.saveError'), type: 'error'});
                    })
                } else {
                    axios.post(this.route('user.landings.update', this.item.id), this.item).then(() => {
                        console.log('save success');
                        this.$emit('updated');
                        this.$store.dispatch('showSnack', {text: this.trans('pages.app.saveSuccess'), type: 'success'});
                        this.load();
                    }).catch( (err) => {
                        let errors = {};
                        _.each(err.response.data.errors, (list, name) => {
                            _.set(errors, name, list);
                        });
                        this.errors = errors;
                        console.log(err.response.data);
                        this.$store.dispatch('showSnack', {text: this.trans('pages.app.saveError'), type: 'error'});
                    })
                }
            },
        },
        watch:{
            'item.settingsArray.utmByPassSettings.custom'(){
                if (!this.item.settingsArray.utmByPassSettings.fields.length){
                    this.addCustomField();
                }
            }
        },
        computed: {
            formTitle() {
                return !this.item.id
                    ? this.trans('pages.landing.newDialogTitle')
                    : this.trans('pages.landing.editDialogTitle');
            },
            features() {
                if (
                    this.options &&
                    this.options.vendorSettings &&
                    this.options.vendorSettings.landing &&
                    this.options.vendorSettings.landing.features &&
                    this.options.vendorSettings.landing.features.length
                ) {
                    return this.options.vendorSettings.landing.features;
                } else {
                    return null;
                }
            },
        }
    }
</script>
<style>
    .draggable {
        cursor: row-resize;
    }
</style>
