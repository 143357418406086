<template>
    <v-card>
        <v-toolbar
                dark
                color="primary"
        >
            <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn v-if="item.id" dark text @click="save">{{ trans('buttons.common.save') }}</v-btn>
                <v-btn v-else dark text @click="save">{{ trans('buttons.common.create') }}</v-btn>
                <v-btn
                        icon
                        dark
                        @click="$emit('close')"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>

        <v-card-text>
            <v-container>
                <v-row>
                    <v-col v-for="(fields, type) in item.offerTypesFields" cols="12" md="4" lg="3" :key="type">
                        <v-list>
                            <v-subheader>{{ trans('offerType.'+type) }}</v-subheader>
                            <v-list-item v-for="(fieldData, field) in fields" :key="type+field">
                                <v-list-item-action>
                                    <v-checkbox
                                            :key="type+field"
                                            v-model="item.settingsArray.hideOfferElements[type][field]"
                                    ></v-checkbox>
                                </v-list-item-action>
                                <v-list-item-title>{{ trans(fieldData.label) }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="$emit('close')">{{ trans('buttons.common.cancel') }}</v-btn>
            <v-btn v-if="item.id" color="primary" outlined @click="save">{{ trans('buttons.common.save') }}</v-btn>
            <v-btn v-else color="primary" outlined @click="save">{{ trans('buttons.common.create') }}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import formValidate from "@/mixins/formValidate";
    export default {
        name: "LandingOfferTypesHideElementsForm",
        mixins: [formValidate],
        components: {
        },
        props: {
            itemId: {
                type: [Number, undefined],
            },
            itemData: {
                type: [Object, undefined]
            }
        },
        data() {
            return {
                item: {
                },
                showRoute: 'user.landing.show',
                error: null,
                options: null,
            }
        },
        created() {
            this.load();
            // this.landingDesignChange();
            console.log('mounted')
        },
        methods: {
            load(){
                let id = this.itemId || this.item.id;
                if (id) {
                    axios.get(this.route(this.showRoute, id)).then(response => {
                        this.item = response.data;
                        this.loadOptions();
                    }).catch(err => {
                        this.error = err.response.data;
                    });
                } else if (this.itemData) {
                    this.item = this.itemData;
                }

            },
            save(){
                axios.post(this.route('user.landings.saveHideOfferElements', this.item.id), {
                    elements: this.item.settingsArray.hideOfferElements,
                }).then( () => {
                    this.$emit('saved');
                    this.$store.dispatch('showSnack', {text: this.trans('pages.app.saveSuccess'), type: 'success'});
                })
            },
        },
        computed: {
            formTitle() {
                return this.trans('pages.hideOfferElements.title');
            },
        }
    }
</script>
